<template>

    <div>

        <app-cancel-account
        v-if="showCancelAccount"
        @close="showCancelAccount = false"
        >
        </app-cancel-account>

        <app-change-plan
        v-if="showChangePlan"
        @close="showChangePlan = false"
        >
        </app-change-plan>

    <div id="details-container" v-if="userDetails">

            <v-card class="status-pending details-card card-shadow" v-if="accountStatus == 'PENDING_CANCEL'">
                <div class="status-pending-content" >
                    <i class="material-icons icon card-icon">highlight_off</i>
                    <h4>{{$t('account.statusDeleteTitle')}}</h4>
                    <div>{{$t('account.statusDeleteText')}}</div>
                </div>
            </v-card>

            <v-card class="status-pending details-card card-shadow" v-if="accountStatus == 'PENDING_CHANGE'">
                <div class="status-pending-content">
                    <i class="material-icons icon card-icon">error_outline</i>
                    <h4>{{$t('account.statusUpdatingTitle')}}</h4>
                    <div>{{$t('account.statusUpdatingText')}}</div>
                </div>
            </v-card>

            <v-card class="status-pending details-card card-shadow" v-if="accountStatus == 'LOCKED'">
                <div class="status-pending-content">
                    <i class="material-icons icon card-icon">error_outline</i>
                    <h4>{{$t('account.statusLockedTitle')}}</h4>
                    <div>{{$t('account.statusLockedText')}}</div>
                </div>
            </v-card>

            <div id="user-details" class="details-card content-card">

                <h3>{{$t('account.yourDetails')}}</h3>

                <div>
                    <h4>{{$t('account.accountEmail')}}:</h4>
                    {{userDetails.Email}}
                </div>


                <div class='details-card-action-menu' @click.stop="">

                <v-menu
                offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon >more_vert</v-icon>
                    </v-btn>


                </template>

                <v-list>
                <v-list-item @click="showCancelAccount = true">
                    <v-list-item-title>{{$t('account.cancelAccount')}}</v-list-item-title>
                </v-list-item>
                </v-list>

                </v-menu>
                </div >

    </div>

    <div id="usage-details" class="details-card content-card" v-if="userPlan" >

        <h3>{{$t('account.usageAndQuota')}}</h3>

        <div>
            <h4>{{$t('account.transRemaining')}}:</h4>
            {{remainingQuota + " minutes"}}
        </div>

        <div>
            <h4>{{$t('account.uploadRemaining')}}:</h4>
            {{uploadRemaining | prettyBytes}}
        </div>

    </div>


    <div id="paid-plan-details" class="details-card content-card" v-if="userPlan" >

        <h3>{{$t('account.currentPlan')}}</h3>

        <div>
            <h4>{{$t('account.planName')}}:</h4>
            {{userPlan.Name}}
        </div>

        <div>
            <h4>{{$t('account.freeMonthlyTranscriptions')}}:</h4>
            {{userPlan.FreeTranscriptionMinutes + " minutes"}}
        </div>

        <div v-if="userPlan.AllowExtraTranscription">
            <h4>Additional Transcription Cost:</h4>
            {{"$" + userPlan.ExtraTranscriptionCharge + " per minute"}}
        </div>

        <div v-if="userPlan.UploadLimit != 0">
            <h4>{{$t('account.freeMonthlyUploads')}}:</h4>
             {{userPlan.UploadLimit | pretty-bytes }}
        </div>

        <div v-if="userPlan.TranscriptionLimit">
            <h4>{{$t('plans.transcriptionLimit')}}:</h4>
             {{userPlan.TranscriptionLimit + " minutes" }}
        </div>
    </div>

    <div id="available-plans" v-if="(userPlan && userDetails)" class="plan-section">

    <div v-if="plans">
        <h3>{{$t('plans.paidPlans')}}</h3>
        <h4>{{$t('plans.paidPlansDesc')}}</h4>
    </div>

        <div class="plan-box">
            <app-plan-card
            v-for="plan in displayPlans"
            :key="plan.PlanID"
            :plan="plan"
            :email="userDetails.Email"
            :currPlan="userPlan.PlanID"
            :freeTrial="userDetails.FreeTrial"
            @changePlan="startChangePlan"
            ></app-plan-card>

        </div>

    </div>
        <div id="available-packs" v-if="(userPlan && userDetails)" class="plan-section">

        <div v-if="addons">
            <h3>{{$t('plans.packs')}}</h3>
            <h4>{{$t('plans.packsDesc')}}</h4>
        </div>

        <div class="plan-box">
            <app-addon-card
            v-for="addon in addons"
            :key="addon.PlanID"
            :addon="addon"
            ></app-addon-card>
        </div>
    </div>

    </div>

    <div class="bottom-menu">
      <v-btn dark @click="$router.go(-1)" color="#000000"><div class="material-icons">keyboard_arrow_left</div>Back</v-btn>
    </div>
    </div>

</template>

<script>
import PlanCard from'./../components/account/PlanCard'
import AddonCard from'./../components/account/AddonCard'
import CancelAccountMD from '../components/account/CancelAccountMD'
import ChangePlanMD from './../components/account/ChangePlanMD'
import requests from './../requests'

export default {
  data() {
    return {
        showCancelAccount: false,
        showChangePlan: false,
        defaultPlan: null,
        cbAccount: process.env.VUE_APP_CB_ACCOUNT,
        cbKey: process.env.VUE_APP_CB_API_KEY,
        chargebeeInstance: null,
        plans: [],
        addons: [],
        devCounter: 0,
        }
    },
    components: {
        appPlanCard: PlanCard,
        appAddonCard: AddonCard,
        appCancelAccount: CancelAccountMD,
        appChangePlan: ChangePlanMD,
    },
    methods: {
        requestCancel: function () {
            this.$store.dispatch('REQUEST_CANCELLATION')
        },
        startChangePlan: function (planID) {
            this.defaultPlan = planID
            this.showChangePlan = true
        },
        setPlansAddons: function (planList) {

            planList.data.forEach(plan => {

                if (plan.Recurring && !plan.AddOn && plan.Price > 0) {
                    this.plans.push(plan)
                    return
                } else if (plan.AddOn && !plan.Recurring) {
                    this.addons.push(plan)
                }
            });

        },
        incrementDevMode: function () {

            this.devCounter ++

            if (this.devCounter > 8) {
                this.$store.dispatch('TOGGLE_DEV_MODE', true)
            }
        }
    },
    computed: {
        userDetails () {
            return this.$store.getters.USER_DETAILS
        },
        userPlan () {
            return this.$store.getters.USER_PLAN
        },
        displayPlans () {
            return this.plans
        },
        currentTranscribeQuota (){
            return this.$store.getters.CURRENT_DURATION_UPLOADING
        },
        pendingQuota () {
            return this.userDetails.PendingTranscribeQuota
        },
        totalQuota () {
            return this.userDetails.TranscribeQuota
        },
        remainingQuota () {

            if (this.totalQuota - this.pendingQuota >= 0) {
                return this.totalQuota - this.pendingQuota
            } else {
                return 0
            }
        },
        uploadRemaining () {
            const usedUploads = this.userDetails.UsedUploadQuota ? this.userDetails.UsedUploadQuota : 0
            return this.userDetails.UploadQuota - usedUploads
        },
        accountMode () {
            return this.$store.getters.ACCOUNT_MODE
        },
        accountStatus() {
            return this.$store.getters.ACCOUNT_STATUS
        },
        devMode () {
            return this.$store.getters.DEV_MODE
        }
    },
    mounted () {

        let self = this

        this.$store.dispatch('SET_USER_DETAILS')

        this.chargebeeInstance = window.Chargebee.init({
            site: this.cbAccount,
            publishableKey: this.cbKey
        })


        requests.getPlans()
        .then(function(response) {
            // self.plans = response.data
            self.setPlansAddons(response.data)

        })

        // requests.getAddons()
        // .then(function(response) {
        //     // console.log(response)
        //     self.addons = response.data
        // })



    },
    // created() {



    // }
}


</script>

<style scoped>


#details-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1em;
}

.details-card {
    margin: 1em;
    text-align: left;
    padding: 24px 48px;

    /* border-radius: 24px; */
}

#details-container .status-pending {
    padding: 14px 48px;
    background-color: #12a8df10;
}

#trial-warning {
    padding: 14px 48px;
    background-color: #df501210;
}

.status-pending-content {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
}

.status-pending-content div {
    margin-left: 12px;
}

#paid-plan-details div, #user-details div, #usage-details div {
    margin: 10px 0px;
    color: rgb(0,0,0, 0.75)
}

.details-card h3 {
    color:  #005887;
    margin: 0px auto 12px auto;
}

.details-card h4 {
    display: inline;
}

.plan-box {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.addon-box {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.ft-warning {
    color: #df5012;
    font-size: 18px;
}

.details-card-action-menu {
    position: absolute;
    top: 16px;
    right: 3px;
}

.details-card-action-menu button {
    color: rgba(0, 0, 0, 0.33)
}

.plan-section h3, .plan-section h4 {
    text-align: left;
    margin: 0 auto;
    width: 95%;
}

.plan-section h3 {
    font-size: 1.6em;
}

/* .manage-acc-btn {
    border: 1px solid red;
    position: absolute;
    right: 1px;
    bottom: 2px;
} */


@media (min-width: 1px) and (max-width: 800px) {

    /* #details-container {
        width: 100%;
        margin-bottom: 10em;
    } */

    .plan-box {
        width: 100%;
        padding: 1em;
        flex-flow: column;
        align-items: center;
    }
}

@media (min-width: 1px) and (max-width: 800px) {


    .plan-card {
        margin: 0 auto;

    }
}


</style>