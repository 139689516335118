<template>

<div class="plan-card content-card" v-bind:class="isCurrentPlan ? 'current-plan' : ''">

    <cancel-paid v-if="showPayCancel"
    @close="showPayCancel = false"
    ></cancel-paid>

    <div class="plan-details">

        <div class="plan-header">
            <h3>{{plan.Name}}</h3>
        </div>

        <div class="plan-description" v-if="plan.Description">
            <p>{{plan.Description}}</p>
        </div>

    </div>

    <div class="plan-features" v-if="plan.Features">

        <div v-for="(feature, index) in plan.Features" :key="index" class="feature-line">

            <i class="material-icons icon card-icon"
                >check</i>
            {{feature}}
        </div>
         </div>

    <div>

        <div class=price-box>
            <p class="plan-price">${{plan.Price/ 100}}</p>
            <p class="plan-currency">USD</p>
            <p>per month</p>
        </div>

        <div v-if="currPlan != plan.PlanID">
            <v-btn class="plan-cta" color='#0BAAFF'
            @click="openCheckout"
            :disabled="isCurrentPlan"
            >{{$t('plans.signUp')}}</v-btn>
        </div>

        <div v-if="currPlan == plan.PlanID">
            <v-btn class="plan-cta accent-background"
            @click="showPayCancel = true"
            >{{$t('plans.cancelPaidPlan')}}</v-btn>
        </div>

    </div>


</div>

</template>

<script>
import requests from './../../requests.js'
import CancelPaidMD from './CancelPaidMD'
import EventBus from './../../eventBus'

export default {
    props: ['plan', 'email', 'currPlan', 'freeTrial'],
    components: {
        cancelPaid: CancelPaidMD
    },
    data () {
        return {
            showPayCancel: false,
        }
    },
    methods: {
        openCheckout () {

        let chargebeeInstance = window.Chargebee.getInstance();
        var self = this

        const planDetails = {
            "PlanID" : this.plan.PlanID,
            // "Email" : this.email,
            // "CBCustomerID": this.CBCustomerID
        }

            chargebeeInstance.openCheckout({
            // This function returns a promise that resolves a hosted page object.
            // If the library that you use for making ajax calls, can return a promise, you can directly return that

                hostedPage: function() {
                    return requests.getCheckout(planDetails).then((response) => response.data);
                },
                success: function() {
                    self.updateUserDetails()
                }
            })
        },
        updateUserDetails () {

            // this.$store.dispatch('UPDATE_CB_USER', this.plan.id)

            EventBus.$emit("SHOW_MESSAGE", this.$t('plans.planUpdatedMsg'), "success")

            let self = this
            setTimeout(function () {
                self.$store.dispatch('SET_USER_DETAILS')
            }, 10000)

        },
    },
    computed: {
        isCurrentPlan () {
            return this.plan.PlanID == this.currPlan ? true : false
        },
    }

}

</script>


<style scoped>

.plan-card {
    margin: 1em;
    padding: 30px;
    width: 100%;
    display: flex;
    flex-flow: column;
    flex-flow: 2;
    justify-content: space-between;
}

.price-box{
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.plan-price{
    font-size: 36px;
    display: inline;
}

.plan-currency{
    font-size: 12px;
    display: inline;
    color: rgb(100,100,100);
    margin-left: 4px;
}

.plan-header {
    font-size: 1.5em;
    color: #005887;
    text-align: left;
}

.plan-description {
    font-size: 1.2em;
    text-align: left;
    font-weight: 700;
}

.plan-features {
    margin: 0.5em auto;
    width: 100%;
}

.feature-line {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 6px;
    font-size: 1.1em;
}

.feature-line i {
    color: rgb(49, 203, 26)
}

.current-plan-banner {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: grey
}

.plan-cta {
    /* position: absolute; */
    /* right: 2em; */
    /* bottom: 3em; */
    color:  white;
    width: 100%;
    border-radius: 4px;
    font-weight: 700;
}

.current-plan .plan-cta {
    color: #4a4a4a;
}
@media (min-width: 1px) and (max-width: 800px) {


    .addon-card {
        margin: 0 auto;

    }
}



</style>