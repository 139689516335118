<template>

<div class="addon-card content-card">

    <div class="addon-details">

        <div class="addon-header">
            <h3>{{addon.Name}}</h3>
            <div class="addon-label">{{$t('plans.oneTimeCharge')}}</div>
        </div>

        <div class="addon-description">
            <p>{{addon.Description}}</p>
        </div>

    </div>

    <div class="addon-features" v-if="addon.Features">

        <div v-for="(feature, index) in addon.Features" :key="index" class="feature-line">

            <i class="material-icons icon card-icon"
                >check</i>
            {{feature}}
        </div>
         </div>

    <div>

        <div class=price-box>
            <p class="addon-price">${{addon.Price/ 100}}</p>
            <p class="addon-currency">USD</p>
        </div>

        <div>
            <v-btn class="addon-cta" color='#0BAAFF'
            @click="openCheckout"
            >{{$t('plans.purchase')}}</v-btn>
        </div>


    </div>
</div>

</template>

<script>
import requests from './../../requests.js'
import EventBus from './../../eventBus'

export default {
    props: ['addon'],
    methods: {
        openCheckout () {

        let chargebeeInstance = window.Chargebee.getInstance();
        var self = this

        const addonDetails = {
            "PlanID" : this.addon.PlanID,
            // "Email" : this.email
        }

            chargebeeInstance.openCheckout({
            // This function returns a promise that resolves a hosted page object.
            // If the library that you use for making ajax calls, can return a promise, you can directly return that

                hostedPage: function() {
                    return requests.getAddonCheckout(addonDetails).then((response) => response.data);
                },
                success: function() {
                    self.updateUserDetails()
                }
            })
        },
        updateUserDetails () {
            // this.$store.dispatch('SIGNUP_USER', this.addon.id).then(`

            EventBus.$emit("SHOW_MESSAGE", "Addon successfully applied. Details will update shortly.", "success")

            let self = this
            setTimeout(function () {
                self.$store.dispatch('SET_USER_DETAILS')
            }, 10000)
            //)
        },
    },


}

</script>


<style scoped>

.addon-card {
    margin: 1em;
    padding: 30px;
    width: 100%;
    display: flex;
    flex-flow: column;
    flex-flow: 2;
    justify-content: space-between;
}

.price-box{
    width: 100%;
    margin: 0 auto;
    /* border: 1px solid red; */
    text-align: left;
}

.addon-price{
    font-size: 36px;
    display: inline;
}

.addon-currency{
    font-size: 12px;
    display: inline;
    color: rgb(100,100,100);
    margin-left: 4px;
}

.addon-header {
    font-size: 1.5em;
    color: #005887;
    text-align: left;
    display: flex;
    align-items: center;
}

.addon-label {
    font-size: 0.6em;
    margin: 0 1em;
    background-color: #4a4a4a70;
    border-radius: 4px;
    color: white;
    padding: 0.2em 0.4em;
    position: absolute;
    top: 1em;
    right: 0em;
}

.addon-description {
    font-size: 1.2em;
    text-align: left;
    font-weight: 700;
}

.addon-features {
    margin: 1em auto;
    width: 100%;
}

.feature-line {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 12px;
    font-size: 1.1em;
}

.feature-line i {
    color: rgb(49, 203, 26)
}

.current-addon-banner {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: grey
}

.addon-cta {
    /* position: absolute; */
    /* right: 2em; */
    /* bottom: 3em; */
    color:  white;
    width: 100%;
    border-radius: 4px;
    font-weight: 700;
}





</style>