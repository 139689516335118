<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">

      <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('account.modalChangePlanTItle')}}</h2>
            <v-btn icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
            </v-btn>
      </div>

    <div class="modal-content">
    <p class="modal-header-caption">{{$t('account.modalChangePlanDesc')}}:</p>

    <v-select
          :items="planList"
          outline
          label="*Please select your new plan (mandatory)"
          v-model="newPlan"
    ></v-select>
    </div>

    <div class="button-row">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('actions.cancel')}}
            </v-btn>

            <v-btn
            class="focus-button"
            color="blue"
            light
            :disabled="newPlan == ''"
            @click="confirmChange"
            >{{$t('actions.confirm')}}</v-btn>

    </div>
          </div>
        </div>
      </div>
  </transition>
</template>

<script>
import EventBus from './../../eventBus'


export default {
  data () {
    return {
      newPlan: "",
      planList: [
      ]
    }
  },
  // props: ['defaultPlan'],
  computed: {
    plans () {
      return this.$store.getters.PLANS
    },
    userPlan () {
      return this.$store.getters.USER_PLAN
    },
  },
  mounted () {

    this.plans.forEach(plan => {

      if (plan.id != this.userPlan.PlanID) {
        this.planList.push(
          plan.name + " ($" + plan.price / 100 + " USD per month)"
        )
      }
    });

  },
  methods : {
    confirmChange: function () {

      const accRequest = {
        "NewPlan" : this.newPlan,
      }

      this.$store.dispatch('REQUEST_CHANGE', accRequest)
      EventBus.$emit('ACCOUNT_CHANGE')
      this.$emit('close')
    }
  }
}

</script>

<style scoped>

.rec-names {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 40%;
  margin: 0 auto;
  padding: 12px 0;
}

.modal-sub-header {
  margin-top: 20px;
  color: rgba(0, 0, 0, .75)
}

.modal-header-caption {
  width: 90%;
  margin: 24px auto
}

.rec-summary-name {
  display: flex;
  align-items: center;
  margin: 8px 12px 8px 0px;
  color: rgba(0, 0, 0, .33)
}

.rec-name {
  font-size: 18px;
  color: rgba(0, 0, 0, .75)
}

</style>
