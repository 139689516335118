<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">

      <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('account.modalCancelPaidTitle')}}</h2>
            <v-btn icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
            </v-btn>
      </div>

    <div class="modal-content">
    <h3 class="modal-sub-header">{{$t('account.modalCancelMsg')}}</h3>

    <p class="modal-header-caption">{{$t('account.modalCancelPaidDesc')}}</p>

    <v-select
          :items="reasons"
          outline
          label="*Please select one option (mandatory)"
          v-model="reason"
    ></v-select>

    <v-textarea
    outline
    v-model="comments"
    label="Please provide any additional comments"
    >

    </v-textarea>
    </div>

    <p class="modal-header-caption">{{$t('account.modalCancelPaidChangeMsg')}}</p>

    <div class="button-row">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('actions.cancel')}}
            </v-btn>

            <v-btn
            class="focus-button"
            color="#0BAAFF"
            :disabled="reason == ''"
            @click="confirmCancel"
            >{{$t('actions.confirm')}}</v-btn>

    </div>
          </div>
        </div>
      </div>
  </transition>
</template>

<script>
import EventBus from './../../eventBus'

export default {
  data () {
    return {
      reason: "",
      comments: "",
      reasons: [
        this.$t('account.modalCancelReason6'),
        this.$t('account.modalCancelReason2'),
        this.$t('account.modalCancelReason3'),
        this.$t('account.modalCancelReason4'),
        this.$t('account.modalCancelReason5')
      ]
    }
  },
  methods : {
    confirmCancel: function () {

      const accRequest = {
        "Reason" : this.reason,
        "Comments": this.comments
      }

      this.$store.dispatch('REQUEST_CANCELLATION', accRequest).then(
      this.$emit('close')
      )

      EventBus.$emit('ACCOUNT_CHANGE')
    }
  }
}

</script>

<style scoped>


.modal-sub-header {
  margin-top: 0;
  color: rgba(0, 0, 0, .75)
}

.modal-header-caption {
  width: 90%;
  margin: 1em auto
}



</style>
